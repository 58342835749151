.ag-theme-alpine {
  --ag-grid-size: 7px;
  --ag-list-item-height: 60px;
  /* --ag-borders: none;
  --ag-row-border-width: 1px;
  --ag-row-border-color: rgb(0, 0, 0, 0.3); */
  --ag-foreground-color: #3f3f46;
  --ag-header-height: 60px;
  --ag-header-foreground-color: #1f2937;
  --ag-header-background-color: #f4f4f5;
  --ag-header-cell-moving-background-color: rgba(19, 82, 177, 1);
  --ag-font-size: 15px;
  --ag-font-family: Roboto, sans;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #babfc7;
}

/* .ag-cell,
.ag-ltr {
  border-right: 1px solid #babfc7 !important;
} */

.ag-theme-boq {
  --ag-grid-size: 7px;
  --ag-list-item-height: 60px;
  --ag-header-height: 50px;
  --ag-foreground-color: #3f3f46;
  --ag-header-foreground-color: #1f2937;
  --ag-header-background-color: #f4f4f5;
  --ag-header-cell-moving-background-color: rgba(19, 82, 177, 1);
  --ag-font-size: 15px;
  --ag-font-family: Roboto, sans;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #babfc7;
}

.ag-theme-boq .ag-ltr .ag-cell {
  border-right: 1px solid #babfc7;
}
